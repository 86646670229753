@import './variables';

.collection-sidebar-dropdown {
    position: absolute;
    right: 23px;
    top: 40px;
    width: fit-content;
    max-width: 160px;
    overflow: hidden;
    transform: scale(0);
    transform-origin: top right;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    transition: all 0.2s ease-out;

    &.open {
        transform: scale(1);
    }

    .dropdown-item {
        padding: 0 10px;
        height: 30px;
        cursor: pointer;
        color: #4d4d4d;

        height: 30px;
        font-size: 12px;
        line-height: 2.5;
        font-family: @showcase-font-family;
        font-weight: normal;

        &:hover {
            background-color: #fafafa;
        }
    }
}
