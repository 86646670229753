.sp-spinner {
    width: 100%;
    height: 100%;
    display: block;
    flex-shrink: 0;
    position: relative;
}
.sp-spinner.sp-primary {
    color: #2d2e83;
}
.sp-spinner .sp-spinner__buffer {
    border-color: #d9d9d9;
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border-width: 1.6px;
}
.sp-spinner .sp-spinner__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    direction: ltr;
}
.sp-spinner .spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    white-space: nowrap;
}
.sp-spinner .circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.sp-spinner .circle-clipper > .stroke {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200%;
    border-style: solid;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    border-width: 1.6px;
}
.sp-spinner .circle-clipper.left > .stroke {
    left: 0;
    border-right-color: transparent !important;
    transform: rotate(135deg);
}
.sp-spinner--indeterminate .sp-spinner__container {
    animation: sp-spinner-container-rotate 1568ms linear infinite;
}
.sp-spinner--indeterminate .spinner-layer {
    animation-name: sp-spinner-fill-unfill-rotate;
    animation-duration: 5332ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-iteration-count: infinite;
}
.sp-spinner--indeterminate .circle-clipper > .stroke {
    animation-duration: 1333ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-iteration-count: infinite;
}
.sp-spinner--indeterminate .circle-clipper.left > .stroke {
    transform: rotate(129deg);
    animation-name: sp-spinner-left-spin;
}
@keyframes sp-spinner-container-rotate {
    to {
        transform: rotate(360deg);
    }
}
@keyframes sp-spinner-fill-unfill-rotate {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    to {
        transform: rotate(1080deg);
    }
}
@keyframes sp-spinner-left-spin {
    0% {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(130deg);
    }
}
