@import 'mixins';
@import 'variables';

@asset-viewer-background: transparent;
@item-spacing: 50px;
@header-default-height: 64px;
@max-width: 896px;
@items-min-horizontal-margin: 50px;
@sidebar-header-height: 48px;
@sidebar-subheader-height: @sidebar-header-height;
@sidebar-footer-height: @sidebar-subheader-height;
@sidebar-footer-border-height: 1px;
@sidebar-toggle-height: 44px;
@sidebar-border-color: rgba(0, 0, 0, 0.12);
@sidebar-thumbnail-horizontal-padding: 35px;

.collection-content,
.collection-sidebar {
    &.with-download-bar {
        bottom: @download-bar-height;
    }
}
.collection-sidebar-container {
    .button-close-web-wrapper {
        position: absolute;
        left: 0px;
        width: @sidebar-subheader-height - 1;
        height: @sidebar-subheader-height - 1;
        background: #f2f2f2;
        z-index: 2;
        .button-close__web {
            z-index: 2;
            margin: 3px 4px;
            color: rgba(0, 0, 0, 0.54);
            left: 0px;
        }
    }
}
.collection-content {
    .absolute-box(64px, 0, 0, @sidebar-width);
    overflow-x: hidden;

    &.animate {
        transition: left, bottom 0.3s ease-in-out;
    }

    .without-sidebar & {
        transition: initial;
        left: 0;
    }

    .show-privacy-warning & {
        bottom: @privacy-warning-height;
    }

    .collection-sidebar-closed & {
        left: @sidebar-subheader-height;
    }
}

// NO IDEA WHY THIS IS static, this breaks the disabling of scrolling, should be `fixed`
// TODO FIGURE THIS OUT

// Put this to static + hide content and header
body.showpad-viewer-no-scroll {
    position: static;
    background: @asset-viewer-background; // for better orientation change behaviour

    .content,
    .header {
        display: none;
    }
}

.collection-sidebar-mixin(@top: 64px) {
    .absolute-box(@top, auto, 0, 0);
    width: @sidebar-width;

    .show-privacy-warning & {
        bottom: @privacy-warning-height;
    }

    background: #f5f5f5;
    border-right: 1px solid @sidebar-border-color;
    transition: all 0.2s linear;
}

.content-wrapper {
    .viewer-app-container:not(.single-item-iframe-presentation-renderer) {
        .vrow:last-child {
            /* add a space at the bottom, so that controls are not overlapping video controls */
            padding-bottom: 90px;
        }
    }
}

.shared-collection {
    &.collection-sidebar-closed {
        @media all and (min-width: 521px), screen and (min-width: 737px) and (orientation: landscape) {
            .collection-sidebar {
                // sidebar - header width (height)
                transform: translate3d(-208px, 0, 0);
            }

            .collection-sidebar-close .spsc-icon-close {
                display: none;
            }

            .collection-sidebar-close .spsc-icon-grid-large {
                display: block;
                font-size: 20px;
                font-weight: 200;
            }

            .collection-sidebar-thumbs {
                opacity: 0;
            }
        }

        @media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
            .collection-sidebar {
                transform: translateX(-100%) translateY(0);
            }
        }
    }
}

body.fullscreen,
body.webkit-full-screen {
    .shared-collection {
        &.collection-sidebar-closed {
            @media all and (min-width: 521px), screen and (min-width: 737px) and (orientation: landscape) {
                .collection-sidebar {
                    // sidebar - header width (height)
                    transform: translate3d(-208px, 0, 0);
                }
            }
        }
    }
}

.collection-sidebar {
    .collection-sidebar-mixin();

    -webkit-overflow-scrolling: touch;

    overflow-x: hidden;
    overflow-y: auto;

    .collection-sidebar-thumbs {
        width: 100%;
        opacity: 1;
        top: @sidebar-subheader-height;
        position: absolute;
        bottom: 0;
        height: auto;
        overflow-x: hidden;
    }

    .collection-sidebar-close {
        &:focus,
        &:active {
            box-shadow: none;
        }

        .spsc-icon-close {
            display: block;
        }

        .spsc-icon-grid-large {
            display: none;
        }
    }

    .active {
        background-color: rgba(214, 214, 214, 0.6);
    }

    .thumbnail {
        cursor: pointer;
        padding: 20px @sidebar-thumbnail-horizontal-padding;

        img {
            max-width: 96px;
            max-height: 120px;
            margin: 0 auto;
            display: block;
        }

        label {
            .ellipsis();

            display: block;
            margin: 5px 0;
            padding: 2px;

            background-color: rgba(250, 250, 250, 0.95);
            color: rgba(0, 0, 0, 0.54);
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            border-radius: 2px;
        }
    }

    .sidebar-item-not-available {
        font-size: 12px;
        .item-not-available();
    }

    .collection-sidebar-subheader {
        display: flex;
        align-items: center;
        border-bottom: 1px solid @sidebar-border-color;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 600;
        position: absolute;
        z-index: 1;
        width: @sidebar-width - 1;
        background: #f2f2f2;
        height: @sidebar-subheader-height;
        line-height: @sidebar-subheader-height;
        padding-right: 15px;

        button {
            color: inherit;
        }
        .collection-sidebar-download-all-button {
            margin-left: auto;
        }

        .button:focus,
        .button:active {
            box-shadow: none;
        }
        .button-light {
            margin: 3px 10px;
            visibility: hidden;
        }

        @media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
            width: 100%;
        }
    }
    .closed .button-close {
        position: absolute;
    }
    .closed .collection-sidebar-download-all-button {
        margin-left: 0px;
    }
}

.collection-sidebar-header {
    .collection-sidebar-mixin();

    height: @sidebar-header-height;
    border-bottom: 1px solid @sidebar-border-color;
    display: none;

    line-height: @sidebar-header-height;
    font-size: 15px;
    font-weight: 600;

    color: rgba(0, 0, 0, 0.54);
    text-indent: 62px;

    transition: transform 0.13s cubic-bezier(0, 0, 0.3, 1);
}

.collection-sidebar-toggle {
    .absolute-box(3px, auto, auto, 8px);
    z-index: 100;
    height: @sidebar-toggle-height;
    width: @sidebar-toggle-height;
    padding: 0;
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    line-height: 20px;
    user-select: none;
    text-indent: 0;

    cursor: pointer;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.shared-collection.sidebar-hidden {
    .collection-sidebar {
        @negativeMargin: (@sidebar-width * -1) + @sidebar-thumbnail-horizontal-padding;
        transform: translateY(-123%);
    }

    .active {
        background-color: transparent;
    }
}

body.in-fullscreen {
    .collection-content {
        left: 0;
        top: 0;
        background: #000;
    }

    .spsc-icon-fullscreen {
        display: none;
    }

    .spsc-icon-close {
        display: block;
    }

    .collection-sidebar-header,
    .collection-sidebar-toggle,
    .collection-sidebar {
        display: none;
    }
}

// On mobile devices (small screens), sidebar should slide over the content, content stays fixed
@media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
    body:not(.fullscreen) {
        .content-wrapper {
            .viewer-app-container-wrapper > .viewer-app-container {
                margin-top: @header-default-height + @sidebar-header-height;
                height: ~'calc(' 100% ~'-' (@header-default-height + @sidebar-header-height) ~')';

                .without-sidebar & {
                    margin-top: @header-default-height;
                }
            }
        }
    }

    .shared-collection {
        .collection-content {
            .absolute-box(0, 0, 0, 0);

            .show-privacy-warning & {
                bottom: 34px;
            }
        }
        .collection-sidebar-container .button-close__web {
            display: none;
        }
        .collection-sidebar {
            z-index: 102;
        }
        .collection-sidebar .collection-sidebar-subheader .button-light {
            visibility: visible;
        }

        .collection-sidebar-header {
            width: auto;
            right: 0;
            z-index: 100;
        }

        .collection-sidebar-toggle {
            z-index: 101;
        }
    }

    .collection-sidebar {
        width: 100%;
        top: 0;
        border-right: none;
    }

    .collection-sidebar-header {
        display: block;
    }

    .collection-sidebar-container {
        .button-close-web-wrapper {
            display: none;
        }
    }
}
