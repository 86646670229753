@import '../style/colors';
@import '../style/mixins';
@import '../style/variables';
@import '../style/spinner';

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
    overflow: auto;
    overflow-y: hidden;
}

body {
    font-family: @showcase-font-family;
    font-size: @showcase-font-size;
    line-height: @showcase-font-size;
    background: @showcase-background-color;

    .loader {
        display: none;
        position: fixed;
        z-index: 102;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        margin: -25px 0 0 -25px;
        transform: translatez(0); // speed hack
    }

    &.loading {
        .privacy-warning,
        .header,
        .content {
            // position: relative;
            // height: 0;
            // overflow: hidden;
        }

        .loader {
            display: block;
        }
    }
}

.hide {
    display: none;
}

// ANIMATION
@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
