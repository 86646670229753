.compliance-modal {
    .compliance-section {
        padding-inline-start: 20px;

        > ::marker {
            font-weight: bold;
        }

        li {
            padding-top: 4px;
        }

        section {
            ol {
                padding-inline-start: 20px;
            }
        }
    }

    .section-title {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
    }
}
