@charset "UTF-8";

@font-face {
    font-family: "sp-showcase";
    src: url('../../tmp/fonts/sp-showcase.eot');
    src: url('../../tmp/fonts/sp-showcase.eot?#iefix') format('eot'),
        url('../../tmp/fonts/sp-showcase.woff') format('woff'),
        url('../../tmp/fonts/sp-showcase.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.sp-showcase() {
    display: inline-block;
    vertical-align: middle;
    font-family: "sp-showcase";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.spsc-icon {
    .sp-showcase();
}

[class^="spsc-icon-"],
[class*="spsc-icon-"] {
    .sp-showcase();
}

.spsc-icon-caret-down{&:before { content: "\EA01"; }}
.spsc-icon-geo-arrw-left{&:before { content: "\EA02"; }}
.spsc-icon-geo-arrw-right{&:before { content: "\EA03"; }}
.spsc-icon-geo-sq-grid{&:before { content: "\EA04"; }}
.spsc-icon-grid-large{&:before { content: "\EA05"; }}
.spsc-icon-minus{&:before { content: "\EA06"; }}
.spsc-icon-plus{&:before { content: "\EA07"; }}
.spsc-icon-resize-diagonal{&:before { content: "\EA08"; }}
.spsc-icon-download{&:before { content: "\EA09"; }}
.spsc-icon-icons-system-actions-close-close-filled{&:before { content: "\EA0A"; }}
.spsc-icon-icons-system-actions-share-share-filled{&:before { content: "\EA0B"; }}
.spsc-icon-icons-system-feedback-info-info-circle-outlined{&:before { content: "\EA0C"; }}
.spsc-icon-icons-unused-system-actions-sidebar-outlined{&:before { content: "\EA0D"; }}


// create proxies to icons
.spsc-icon-fullscreen { .spsc-icon-resize-diagonal; }
.spsc-icon-close { .spsc-icon-icons-system-actions-close-close-filled; }
.spsc-icon-arrow-left { .spsc-icon-geo-arrw-left; }
.spsc-icon-arrow-right { .spsc-icon-geo-arrw-right; }
.spsc-icon-play { .spsc-icon-geo-arrw-right; }