.modal-consent__outer-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
}
.modal-consent__inner-wrapper {
    min-height: 470px;
    max-width: 100%;
}
.modal-consent__wrapper {
    margin: @whitespace--l 0;
}

.consent-modal-open {
    .modal-consent__outer-wrapper {
        display: flex;
    }
    .collection-content {
        .viewer-app-container-wrapper {
            filter: blur(10px);
        }
    }

    .viewer-app-container {
        overflow: hidden !important;
    }
    .action-bar {
        pointer-events: none;
    }
}

.consent-modal-view-content-button {
    color: #4d4d4d;
    font-size: 14px;
}

.consent-overlay {
    display: none;
}

.modal-consent {
    max-width: 100%;
    width: 663px;
    border-radius: 2px;
    background-color: white;
    padding: 60px 40px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
    color: #262626;
    text-align: justify;
}

.modal-consent__header {
    color: #2b2b2b;
    font-size: 32px;
    font-weight: normal;
    text-align: center;
}

.modal-consent__message {
    font-size: 14px;
    color: #262626;
    padding: 4px 0;
    margin-bottom: 24px;
    border-bottom: 2px solid #d8d8d8;
    p {
        margin: 1rem 0;
        line-height: 20px;
    }
    a {
        color: #262626;
    }
}

.modal-consent__accept {
    margin: 0 auto;

    a {
        color: #262626;
    }

    button {
        width: 100%;
        min-height: 40px;
        border: none;
    }
    .modal-consent__buttons {
        margin-top: 40px;
        font-size: 14px;

        button:disabled {
            opacity: 0.4;
        }
    }
    .control {
        align-items: flex-start;
        margin: 0 7px 0 0;
        input {
            z-index: 1;
            cursor: pointer;
        }
    }
    .control__label--custom {
        line-height: 20px;
        letter-spacing: 0.3px;
        padding-left: 14px;
        color: #2b2b2b;
        font-size: 14px;
        text-align: justify;
    }
    .control--checkbox {
        cursor: pointer;
    }
    .control--checkbox input[type='checkbox']:checked + .control__indicator {
        background-color: #2d2e83;
    }
    .control__indicator {
        border: 1px solid #737373;
    }
}

.modal-consent__continue {
    background-color: #2d2e83;
    color: #ffffff;
}

.button--m {
    &.button-tint--reversed {
        background: none;
        border: none;
        margin-top: 16px;
        box-shadow: none;
        &:focus,
        &:active {
            box-shadow: none;
        }
    }
    &.button-outline {
        border: 1px solid #d6d6d6;
    }
}

@media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
    .modal-consent__outer-wrapper {
        align-items: flex-start;
    }
    .modal-consent {
        padding: @whitespace--xxl @whitespace--l;
        margin-top: @sidebar-header-height + @header-default-height;
        border-radius: 0;
    }
}

@media all and (max-height: 520px) {
    .modal-consent__outer-wrapper {
        align-items: flex-start;
    }
}
