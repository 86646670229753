.action-bar {
    width: 450px;
    max-width: 90%;
    margin: 20px 0;
    padding: 10px;
    background: black;
    color: white;
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    height: 53px;
    left: 50%;
    border-radius: 2px;
    transform: translateX(-50%);
    will-change: opacity;
    opacity: 0.8;
    transition: opacity 0.4s ease-in-out;
    contain: strict;
    z-index: 999;
}

.action-bar-small {
    width: 100px;
}

.action-bar.action-bar-hidden {
    opacity: 0;
    pointer-events: none;
}

.action-bar-top {
    top: 0;
}

.action-bar-bottom {
    bottom: 0;
}

.action-bar .action-bar-button-container,
.action-bar .action-bar-info-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.action-bar .action-bar-button-container {
    justify-content: flex-end;
    flex-grow: 10;
}

.action-bar-button-container svg {
    fill: white;
    width: 100%;
    height: 100%;
}

.action-bar-small .action-bar-button-container {
    justify-content: space-around;
}

.action-bar .action-bar-info-container {
    justify-content: space-between;
    min-width: 0;
    width: 100%;
    -ms-flex: 1 10 auto;
    flex-shrink: 10;
}

.action-bar-page-container {
    display: -ms-flexbox;
    display: flex;
    margin: 0 7px;
}

.action-bar-displayname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0;
    -ms-flex: 1 10 auto;
}

.action-bar-previous,
.action-bar-next,
.action-bar-zoom-in,
.action-bar-zoom-out,
.action-bar-download,
.action-bar-fullscreen,
.action-bar-close {
    padding: 7px;
    border-radius: 50%;
    border: 1px solid white;
    margin: 0 3px;
    font-size: 11px;
    touch-action: manipulation;
    width: 27px;
    height: 27px;
    min-width: 27px;
    min-height: 27px;
    cursor: pointer;
}

.action-bar-previous svg,
.action-bar-next svg,
.action-bar-zoom-in svg,
.action-bar-zoom-out svg,
.action-bar-download svg,
.action-bar-fullscreen svg,
.action-bar-close svg {
    height: 11px;
}

.action-bar-small .action-bar-download,
.action-bar-small .action-bar-close {
    border-radius: 0;
    border: none;
    font-size: 14px;
}

.action-bar-next {
    padding-left: 8px;
    margin-right: 8px;
}
.action-bar-zoom-in {
    margin-right: 8px;
}
.action-bar-fullscreen {
    margin-right: 0;
}

.action-bar-zoom-in.disabled,
.action-bar-zoom-out.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media screen and (max-width: 600px) {
    .action-bar {
        height: 47px;
    }
    .action-bar-previous,
    .action-bar-next,
    .action-bar-zoom-in,
    .action-bar-zoom-out,
    .action-bar-download,
    .action-bar-fullscreen {
        margin: 0 3px;
    }
    .action-bar-fullscreen {
        margin-right: 0;
    }
    .action-bar-next {
        margin-right: 6px;
    }
    .action-bar-zoom-in {
        margin-right: 6px;
    }
}
