@import './share/share-modal';
@import './about/about-modal';
@import './compliance/compliance-modal';

@primary-color: #00bb8c;
@modal-width: 600px;
@modal-height: 400px;
@modal-header-height: 50px;
@modal-background-color: #fff;
@modal-header-background-color: #f3f3f2;
@modal-border-color: #e5e5e5;
@modal-reshare-policy-color: #999999;
@modal-padding: 24px;
@modal-transition-duration: 0.25s;

.modal-open {
    overflow: hidden;
    /* important .modal-wrapper definitions are nested in .modal-open */
    .modal-wrapper {
        overflow-y: auto;
        z-index: 102;
    }
    .modal {
        display: block;
    }
}

.modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.modal {
    /* make sure it hovers the main overlay z-index:101*/
    z-index: 102;
    position: relative;

    background: @modal-background-color;
    border-radius: 4px;
    // TODO this shows on mobile
    // border: 1px solid @modal-border-color;

    display: none;
    overflow: hidden;

    width: 100%;
    max-width: @modal-width;
    margin: 74px auto 0 auto;

    transition: transform @modal-transition-duration ease-out;
    color: #262626;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    box-shadow: 0 12px 16px -12px rgba(77, 77, 77, 0.24), 0 12px 32px 8px rgba(77, 77, 77, 0.12), 0 24px 32px 0 rgba(77, 77, 77, 0.16);

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 16px @modal-padding;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;

        .modal-header-title {
            max-width: 280px;
            .ellipsis();
        }

        button {
            color: #4d4d4d;
            margin-right: -8px;
        }
    }

    .modal-body {
        background: @modal-background-color;
        padding: 0 @modal-padding;
        margin: 0;
        min-height: 150px;
        .field-wrapper {
            margin-bottom: 16px;
            label {
                display: flex;
                margin-bottom: 8px;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #262626;
            }
        }
    }
    .modal-footer {
        padding: 16px @modal-padding;
    }
    button:hover {
        &.button-send {
            .button_focus-overlay {
                opacity: 0.08;
            }
        }
        &.button-close {
            .button_focus-overlay {
                opacity: 0.04;
            }
        }
    }
    @media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
        height: 100%;
        margin: 0;
        border-radius: 0;
        max-width: none;
        overflow-y: scroll;
    }
}
