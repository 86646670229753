.ellipsis () {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clearfix () {
    &:before,
    &:after {
        content: ' '; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

.absolute-box (@top: 0, @right: 0, @bottom: 0, @left: 0) {
    position: absolute;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
}

.fixed-box (@top: 0, @right: 0, @bottom: 0, @left: 0) {
    position: fixed;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
}

.unstyled-list (@list-margin: 0, @list-padding: 0, @item-margin: 0, @item-padding: 0) {
    list-style-type: none;

    margin: @list-margin;
    padding: @list-padding;

    > li {
        list-style-type: none;

        margin: @item-margin;
        padding: @item-padding;
    }
}

.item-not-available () {
    position: relative;
    padding-bottom: 50%;

    .not-available-message {
        .absolute-box(45%, 5%, auto, 5%);

        display: inline-block;
        color: rgba(0, 0, 0, 0.2);
        text-align: center;
    }
}

.break-word () {
    word-break: normal;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
