@import '../style/colors.less';

body.error,
body.revoked {
    .content {
        left: 0;
    }
}

body.error {
    .showcase-error-container {
        display: block;
    }
    .showcase-error {
        display: flex;
    }

    .showcase-type.shared-collection,
    .header-actions-list .share-button,
    .header-actions-list .about-button {
        display: none;
    }
}

.showcase-error-container {
    display: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.showcase-error {
    display: none;

    margin-top: 240px;

    @media screen and (max-height: 600px) {
        margin-top: 80px;
    }

    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;

    color: #262626;
    line-height: 20px;

    text-align: center;

    h1 {
        margin-right: 0;
        padding: 0;
    }

    img {
        display: inline-block;
        vertical-align: middle;
        width: 84px;
        height: 84px;
    }

    .empty-message {
        font-size: 20px;
        font-weight: 500;

        p {
            text-align: left;
        }
    }

    .revoked-svg {
        height: 240px;
        width: 240px;
        color: @color-sp-purple-500;
    }
}
