@import 'variables';

.download-bar {
    transform: translateY(@download-bar-height);
    height: @download-bar-height;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1000;
    background-color: #fafafa;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    &.open {
        display: flex;
        transform: translateY(0);
        animation: slideIn 0.3s ease-in-out;
    }

    .download-bar-content {
        display: flex;
        align-items: center;

        .spinner-wrapper {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
    }

    .close-button {
        background: inherit;
        display: inline-block;
        border: none;
        font-size: inherit;
        text-transform: uppercase;
        margin: 0 0 0 24px;
        color: inherit;
        padding: 8px;
        min-width: min-content;
        cursor: pointer;
    }
}

body {
    &.loading {
        .close-button {
            display: none;
        }
    }
}

@keyframes slideIn {
    0% {
        display: none;
        transform: translateY(@download-bar-height);
    }
    1% {
        display: flex;
    }
    100% {
        transform: translateY(0);
    }
}
