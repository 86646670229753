// BASE
@import '../critical/critical';
@import '../font/showcase';

// CONTENT LAYOUTS
@import 'header';
@import 'mixins';
@import 'colors';
@import 'variables';
@import 'layout-revoked';
@import 'action-bar';
@import 'download-bar';
@import 'dropdown';

// COMPONENTS
@import 'asset-viewer-collection';
@import '../components/modal/modal';
@import '../components/modal/consent-modal';

// SP_UIKIT
@import '../../node_modules/@showpad/sp-uikit/less/variables';
@import '../../node_modules/@showpad/sp-uikit/less/alert';
@import '../../node_modules/@showpad/sp-uikit/less/control';
@import '../../node_modules/@showpad/sp-uikit/less/button';

// VENDOR
@import '../../node_modules/tags-input/tags-input.css';
@import '../../node_modules/@showpad/asset-viewer-v2/css/main.css';

.content-wrapper {
    position: relative;
    min-height: 100%;
    z-index: 100;
}

.content {
    // DO NOT PUT POSITION RELATIVE HERE, IT BREAKS SINGLE SHOWCASE SHARES
}

/*
 * Black overlay as background for modals, dialogs, ..
 */
body {
    &.overlay-open {
        .overlay {
            display: block;
            background: rgba(0, 0, 0, 0.3);
            pointer-events: none;
        }
    }
}

body.error {
    .showcase-type.shared-collection {
        display: none;
    }
}

body.show-privacy-warning {
    .privacy-warning {
        display: block;
    }
}

.privacy-warning {
    position: relative;
    z-index: 101;
    display: none;

    line-height: @privacy-warning-height;
    font-size: @privacy-warning-font-size;
    color: rgba(0, 0, 0, 0.6);
    padding: 20px 10px 20px 10px;

    background-color: #d9f5ff;
    border: solid 1px rgba(0, 0, 0, 0.12);

    a {
        color: rgba(0, 0, 0, 0.6);
    }

    .hide-button {
        display: block;
        position: absolute;
        right: 10px;
        top: 6px;

        background: transparent;
        border: none;
        box-shadow: none;

        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;

        &:focus,
        &:active {
            outline: none;
        }

        i {
            vertical-align: middle;
        }
    }

    @media all and (max-width: 520px) {
        font-size: 12px;
    }
}

.overlay {
    .absolute-box();
    display: none;
    z-index: 101;
    background: #f5f5f5;
}

h1 {
    margin-left: 5%;
    margin-right: 5%;
    line-height: 1.2em;
}

button:disabled,
button[disabled] {
    cursor: not-allowed !important;
    opacity: 0.28;
}
button {
    position: relative;
}

.viewer-has-error {
    .showpad-viewer-content p {
        .absolute-box(50%, 0, auto, 0);
        margin: 0;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54) !important;
    }
}

body.fake-fullscreen {
    overflow: hidden;

    .collection-sidebar,
    .collection-sidebar-header {
        display: none;
    }

    .headroom--not-top .collection-content,
    .collection-content {
        left: 0;
        top: 0;
    }
}

.dynamic-renderer {
    > .vrow {
        > .vrow-content-container-wrapper {
            > .vrow-content-container {
                will-change: unset !important;
            }

            > .vrow-content-container-url {
                width: 100% !important;
            }

            > .vrow-content-container-video {
                max-height: calc(~'100vh - 100px');

                video:-webkit-full-screen {
                    width: 100%;
                    height: 100%;
                    max-height: 100vh;
                }
            }
        }
    }
}

.toast-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 24px;
    height: auto;
    z-index: 9999;
    text-align: center;
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);

    > div {
        margin-bottom: 8px;
        animation: fadeSlideIn 0.3s;
    }

    .alert--inline {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 10px 12px;
        border-radius: 2px;
    }

    .icon--alert {
        width: 20px;
        height: 22px;
        margin-right: 12px;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    .alert--title {
        font-size: 13px;
        line-height: 20px;
    }
}

.button-dark {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: rgb(95, 95, 95);
    color: #fff;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
.button-light {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: rgb(228, 228, 228);
    color: rgba(0, 0, 0, 0.54);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
.button-transparent {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: none;
    color: inherit;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
.button-light,
.button-transparent,
.button-dark {
    &:focus,
    &:active {
        outline: none;
    }
}
.button-light:hover,
.button-dark:hover,
.button-transparent:hover {
    cursor: pointer;
}

.sidebar-open {
    .asset-viewer-overlay__main {
        @media all and (max-width: 520px) {
            left: 0px;
        }
        left: 200px;
        &.sidebar-open {
            transition: left 0.3s linear;
        }
    }
    .asset-viewer-overlay__sidebar {
        transform: none;
        &.sidebar-open {
            transition: all 0.3s linear;
        }
    }
    .button-toggle-sidebar__web {
        @media all and (max-width: 520px) {
            display: none;
        }
        display: flex;
    }
}

.sidebar-collapsed {
    .asset-viewer-overlay__main {
        transition: left 0.3s linear;
    }
    .asset-viewer-overlay__sidebar {
        transition: all 0.3s linear;
    }
    .button-toggle-sidebar__web {
        @media all and (max-width: 520px) {
            display: none;
        }
        display: flex;
    }
}

.asset-viewer-overlay__container {
    background: rgba(30, 30, 30, 0.96);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    animation: fadeIn 0.3s;
    .asset-viewer-overlay__header {
        display: none;
        align-items: center;
        height: 48px;
        color: white;
        padding: 0 18px;
        .asset-viewer-overlay__header-title {
            padding: 0 10px;
        }
    }
}

.asset-viewer-overlay__container.sidebar-hidden {
    .asset-viewer-overlay__main {
        left: 0;
    }
}

.asset-viewer-overlay__toolbar {
    color: white;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 20px;
    &__display-name {
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .asset-viewer-overlay__download-button {
        margin-left: auto;
    }
}
.asset-viewer-overlay__main {
    position: absolute;
    top: 64px;
    left: @sidebar-subheader-height;
    right: 0;
    bottom: 0;
}
.asset-viewer-overlay__sidebar {
    position: absolute;
    left: 0;
    top: 64px;
    bottom: 64px;
    width: 200px;
    height: auto;
    z-index: 1;
    transform: translateX(-100%) translateY(0);
}
.asset-viewer-overlay__asset-viewer-container {
    height: 100%;
}

.asset-viewer-overlay__asset-viewer-thumbnail-container,
.asset-viewer-overlay__asset-viewer-container {
    height: 100%;
}

.button-toggle-sidebar__web {
    z-index: 2;
    margin: 8px;
    display: none;
}
.asset-viewer-overlay__sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    color: white;
    .button-dark {
        visibility: hidden;
    }
}

.fade-out {
    animation: fadeSlideOut 0.3s;
}
.button_focus-overlay {
    background-color: currentColor;
    position: absolute;
    border-radius: 4px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

button:hover:enabled .button_focus-overlay {
    opacity: 0.04;
}

.text-field {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 400;
    min-height: 40px;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.23, 1);
    &:focus {
        outline: none;
    }
    &::placeholder {
        .color-iron-grey-500;
    }
}

.text-field-error {
    border-color: @color-crimson-red-700;
    background-color: @color-crimson-red-50;
}

.text-field-active-box-shadow {
    box-shadow: 0 0 4px 0 rgba(77, 77, 77, 0.24);
}
// 1 column full width items
@media only screen and (min-width: 480px) {
    .content {
        padding-left: 7%;
        padding-right: 7%;
    }
}

// 2 columns
@media only screen and (min-width: 768px) {
    .content {
        padding-left: 2%;
        padding-right: 2%;
    }

    h1 {
        margin: 0 2%;
    }

    .header {
        .company-logo {
            margin-right: 280px;
        }
    }
}
@media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
    .asset-viewer-overlay__container {
        .button-toggle-sidebar__web {
            display: none;
        }
    }
    .asset-viewer-overlay__main {
        z-index: 50;
        .absolute-box(@header-default-height + @sidebar-header-height, 0, 0, 0);
    }

    .asset-viewer-overlay__sidebar {
        .absolute-box(0, 0, 0, 0);
        background: inherit;
        z-index: 102;
        width: 100%;
        .button-dark {
            visibility: visible;
        }
    }

    .asset-viewer-overlay__container {
        &.sidebar-collapsed {
            .asset-viewer-overlay__header {
                display: flex;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeSlideIn {
    0% {
        display: none;
        opacity: 0;
        transform: translateY(-24px);
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeSlideOut {
    0% {
        display: block;
        opacity: 1;
        transform: translateY(0);
    }

    99% {
        display: block;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
        transform: translateY(-24px);
    }
}
