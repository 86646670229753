@import 'variables';

@header-default-background-color: #fff;
@header-default-color: rgba(0, 0, 0, 0.87);

@header-actions-list-width: 300px;

/**
 * Hide the header in fullscreen and revoked state
 */
body.fake-fullscreen,
body.revoked {
    .header {
        display: none;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: @header-default-height;
    line-height: @header-default-height;
    z-index: 101;

    padding: 0 10px;
    background: @header-default-background-color;
    color: @header-default-color;
    border-bottom: 1px solid @sidebar-border-color;

    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 0.5px 0 0 rgba(0, 0, 0, 0.06);
    transform: translateZ(0);
    transition: transform 0.13s cubic-bezier(0, 0, 0.3, 1);

    .company-logo {
        @margin: 10px;
        height: @header-default-height;
        flex-grow: 1;
        font-size: 24px;
        font-weight: 400;
        .ellipsis();

        background-size: contain;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: left center;

        &.has-logo {
            border: @margin solid transparent; // fake margin top/left/bottom
        }
    }
}

.header.headroom {
    transform: translateY(-100%);
}

.collection-sidebar-header.headroom {
    transform: translateY(-230%);
}

.header-actions-list {
    display: flex;
    .unstyled-list(0, 0, 0, 0);
    &:first-child {
        margin-right: 8px;
    }
}

@media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
    .header,
    .collection-sidebar-header,
    .collection-sidebar-toggle {
        animation-duration: 0.5s;
        animation-fill-mode: both;
        will-change: transform;
    }

    .content-wrapper.slide-down {
        .header {
            animation-name: slideDown;
        }

        .collection-sidebar-header {
            animation-name: slideDownSub;
        }
    }

    .content-wrapper.slide-up {
        .header {
            animation-name: slideUp;
        }

        .collection-sidebar-header {
            animation-name: slideUpSub;
        }
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}
@keyframes slideUpSub {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-230%);
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}
@keyframes slideDownSub {
    0% {
        transform: translateY(-230%);
    }

    100% {
        transform: translateY(0);
    }
}
