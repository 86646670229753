.share-modal {
    select {
        display: block;
        width: 100%;
    }

    select,
    input {
        font-size: 16px !important; // TODO:: sweet-spot for mobile browsers (prevent automatic zooming)
    }

    .tags-input {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .text-field;
        &:hover {
            .border-color-iron-grey-500;
            .text-field-active-box-shadow;
        }
        &.focus {
            .border-color-iron-grey-600;
            .text-field-active-box-shadow;
        }
        input {
            width: 100%;
            height: 40px;
            &::placeholder {
                .color-iron-grey-500;
            }
        }

        .tag {
            background-color: #f2f2f2;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            display: flex;
            align-items: center;
            border: 1px solid #f2f2f2;
            border-radius: 32px;
            padding: 2px 8px;
            height: 22px;
            span {
                max-width: 166px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: @color-sp-purple-500;
            }
            .tag-remove {
                position: relative;
                cursor: pointer;
                width: 12px;
                height: 12px;
                color: @color-sp-purple-500;
                margin-left: 4px;
                .spsc-icon-close {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .modal-footer {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .reshare-policy {
            font-size: 12px;
            color: @modal-reshare-policy-color;
            a {
                color: @modal-reshare-policy-color;
            }
        }
    }

    .share-body {
        height: 200px;
    }

    .field-wrapper {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .share-error {
        display: none;
        margin: 10px 0;
        padding: 5px 5px;
        border-radius: 2px;
        background: red;
        color: #fff;
    }

    &.has-share-error {
        .share-error {
            display: block;
        }
    }

    .share-recipients .selectize-input {
        border-radius: 2px;
        border: 1px solid #999;
        font-family: inherit;
        font-size: 14px;

        &:focus,
        &:active {
            outline: none;
            border-color: #777;
        }
    }

    textarea {
        .text-field;
        font-family: inherit;
        min-height: 120px;
        resize: none;
        padding-top: 12px;
        padding-bottom: 12px;
        &:hover {
            .border-color-iron-grey-500;
            .text-field-active-box-shadow;
        }
        &:focus {
            .border-color-iron-grey-600;
            .text-field-active-box-shadow;
        }
    }

    .compliance-policy-link {
        text-decoration: underline;
        cursor: pointer;
    }

    .error.share-recipients .tags-input,
    textarea.error,
    input.error,
    select.error {
        .text-field-error;
        &:hover {
            border-color: @color-crimson-red-700;
            box-shadow: 0 0 4px 0 rgb(184, 12, 0, 0.36);
        }
        &:focus {
            border-color: @color-crimson-red-700;
            box-shadow: 0 0 4px 0 rgb(184, 12, 0, 0.36);
        }
    }
    .error .tags-input.focus {
        border-color: @color-crimson-red-700;
        box-shadow: 0 0 4px 0 rgb(184, 12, 0, 0.36);
    }
    .share-recipients {
        // if the item has the name, email is less important
        .item-with-name {
            .email {
                &:before {
                    content: '<';
                }
                &:after {
                    content: '>';
                }
                color: #979797;
                margin-left: 5px;
            }
        }
    }
}
