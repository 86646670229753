@text-color: #575757;
@showcase-background-color: #fafafa;
@showcase-input-border-color: #a2a2a2;
@primary-action-color: #00bb8c;
@default-accent-color: #aaa;
@color-iron-grey-500: #999999;
@color-iron-grey-600: #737373;
@color-sp-purple-500: #2d2e83;
@color-crimson-red-700: #b80c00;
@color-crimson-red-50: #ffeeeb;

.border-color-iron-grey-500 {
    border-color: @color-iron-grey-500;
}
.border-color-iron-grey-600 {
    border-color: @color-iron-grey-600;
}
.color-iron-grey-500 {
    color: @color-iron-grey-500;
}
