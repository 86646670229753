.modal {
    .about-modal {
        .modal-body {
            padding: 24px;
        }
    }
}
.about-modal {
    .section-title {
        font-weight: 600;
        color: #737373;
        font-size: 14px;
        margin-bottom: 16px;

        &.hidden {
            display: none;
        }
    }

    .info-wrapper {
        .clearfix();

        .promo-image {
            display: none;
            float: right;

            width: 192px;
            height: 192px;

            background: transparent 50% 50% no-repeat;
            background-size: contain;
        }

        .company-biography-content {
            .break-word();
        }

        &.with-promo-image {
            .company-biography,
            .promo-image {
                display: block;
            }
        }
    }

    .privacy-info-actions {
        opacity: 0.4;
        margin: 40px auto 10px auto;
        display: block;
        font-size: 12px;
        width: 230px;
        text-align: center;

        .list {
            .unstyled-list(10px 0 10px 10px, 0, 0 5px 0 0, 0);

            > li {
                display: inline;
            }

            > li:last-child {
                margin-left: 1px;
            }

            a {
                color: inherit;
            }
        }
    }
}
.showcase-creator {
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 20px;
    padding-bottom: 20px;
    .hidden {
        display: none;
    }
}
.info-wrapper {
    color: #262626;
    font-size: 13px;
}
.showcase-creator-info {
    font-size: 13px;
    color: #262626;
}
.showcase-creator-name-role {
    margin-bottom: 8px;
}
.showcase-creator-name {
    font-weight: 600;
}
.showcase-creator-role {
    color: #737373;
}
.showcase-creator-phone {
    margin-bottom: 8px;
}
.showcase-creator-email {
    margin-bottom: 8px;
    color: #262626;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.showcase-creator-avatar {
    margin-right: 12px;
}
.showcase-creator-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    background-color: #cfd0d2;
    color: #ffffff;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 300;
}

@media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
    .about-modal {
        .info-wrapper {
            .promo-image {
                float: none;
                margin: 0 auto 20px;
            }

            &.with-promo-image {
                .company-biography {
                    margin-right: 0;
                }
            }
        }
    }
}
